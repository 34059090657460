var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"products"},[_c('div',{staticClass:"main-hero"},[_c('div',{staticClass:"main-hero__text"},[_c('b-container',{attrs:{"fluid":"xl"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8","lg":"7"}},[_c('div',{staticClass:"hero-block"},[_c('h1',{staticClass:"h1-dots"},[_vm._v("Termékeink")]),_c('p',[_vm._v("A Kunsági Éden termékcsalád egyes termékei magyar alapanyagok felhasználásával, hazai munkaerővel készülnek. A környezettudatosság kiemelten fontos számunkra, ezért palackjaink előállításához kevesebb műanyagot használunk fel, valamint termékeink csomagolásához kizárólag felelős erdőgazdálkodásból származó alapanyagot veszünk igénybe, ezzel is csökkentve ökológiai lábnyomunkat. ")]),_c('div',{staticClass:"buy-online--desctop"},[_c('ProdByOnline')],1)])])],1)],1)],1)]),_c('b-container',{staticClass:"buy-online--mobile",attrs:{"fluid":"xl"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ProdByOnline')],1)],1)],1),_c('div',{staticClass:"products__products"},_vm._l((_vm.products),function(product,index){return _c('Product',{key:index,attrs:{"product":product.detailsPage,"productName":product.name}})}),1),_c('section',{staticClass:"recipe-details__other-recipes-section"},[(_vm.recipeList.length > 0)?_c('b-container',{attrs:{"fluid":"xl"}},[_c('b-row',{staticClass:"recipe-details__other-recipes other-recipes-block"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"title-wrap"},[_c('h2',[_vm._v("Receptek")]),_c('router-link',{staticClass:"btn btn--brown",attrs:{"to":{name: 'Recipes'}},nativeOn:{"click":function($event){return _vm.setDataLayer('all_content_click', 'all_recipe_click', '')}}},[_vm._v("További receptek")])],1),_c('OwlCarousel',{attrs:{"items":3,"margin":30,"dots":false,"loop":false,"responsive":{ 
                            0:{
                                items:1,														
                                margin: 10,
                                mouseDrag: true,                                 
                            },      													
                            768:{items:2, mouseDrag:true, margin: 30},
                            992:{items:3, mouseDrag:false, margin:20},                            
                            1300:{items:3, mouseDrag:false}                            
                        }}},_vm._l((_vm.recipeList),function(recipe){return _c('RecipeItem',{key:recipe.id,attrs:{"recipe":recipe}})}),1),_c('div',{staticClass:"btn-mobile-wrap"},[_c('router-link',{staticClass:"btn btn--brown btn--mobile",attrs:{"to":{name: 'Recipes'}},nativeOn:{"click":function($event){return _vm.setDataLayer('all_content_click', 'all_recipe_click', '')}}},[_vm._v("További receptek")])],1)],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"half-left-dc"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }