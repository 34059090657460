<template>
    <div class="products">
        <div class="main-hero">
            <div class="main-hero__text">
                <b-container fluid="xl">
                    <b-row>
                        <b-col cols="12" md="8" lg="7">
                            <div class="hero-block">
                                <h1 class="h1-dots">Termékeink</h1>
                                <p>A Kunsági Éden termékcsalád egyes termékei magyar alapanyagok felhasználásával, hazai munkaerővel készülnek. A környezettudatosság kiemelten fontos számunkra, ezért palackjaink előállításához kevesebb műanyagot használunk fel, valamint termékeink csomagolásához kizárólag felelős erdőgazdálkodásból származó alapanyagot veszünk igénybe, ezzel is csökkentve ökológiai lábnyomunkat. </p>
                                <div class="buy-online--desctop">
                                    <ProdByOnline/>
                                </div>                                
                            </div>   
                        </b-col>
                    </b-row>                   
                </b-container>
            </div>
        </div>
        <b-container class="buy-online--mobile"  fluid="xl">
            <b-row >
                <b-col cols="12">
                    <ProdByOnline/>
                </b-col>
            </b-row>
        </b-container>        
        <!-- Termékek -->
        <div class="products__products" >
            <Product v-for="(product, index) in products" :key="index"
                :product="product.detailsPage" 
                :productName="product.name"
            />  
        </div>
        <!-- Receptek       -->
        <section class="recipe-details__other-recipes-section">
            <b-container fluid="xl" v-if="recipeList.length > 0" >
                <b-row class="recipe-details__other-recipes other-recipes-block">
                    <b-col cols="12">
                        <div class="title-wrap">
                            <h2>Receptek</h2>
                            <router-link class="btn btn--brown" :to="{name: 'Recipes'}" @click.native="setDataLayer('all_content_click', 'all_recipe_click', '')">További receptek</router-link>
                        </div>
                        
                        <OwlCarousel  :items="3" :margin="30" :dots="false"  :loop="false" 
                            :responsive="{ 
                                0:{
                                    items:1,														
                                    margin: 10,
                                    mouseDrag: true,                                 
                                },      													
                                768:{items:2, mouseDrag:true, margin: 30},
                                992:{items:3, mouseDrag:false, margin:20},                            
                                1300:{items:3, mouseDrag:false}                            
                            }">
                                <RecipeItem 
                                    v-for="recipe in recipeList" :key="recipe.id"
                                    :recipe="recipe" 
                                />
                        </OwlCarousel>

                        <div class="btn-mobile-wrap">
                            <router-link class="btn btn--brown btn--mobile" :to="{name: 'Recipes'}" @click.native="setDataLayer('all_content_click', 'all_recipe_click', '')">További receptek</router-link>        
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <div class="half-left-dc"></div> 
    </div>
</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'
import ProdByOnline from '@/components/pages/Products_buy_online.vue'
import Product from '@/components/pages/Product.vue'
import OwlCarousel from 'vue-owl-carousel'
import RecipeItem from '@/components/pages/RecipeItem.vue'

export default {
    data(){
        return{
           recipeList:[]
        }
    },
    mixins: [apiH],   
    components:{
        ProdByOnline,
        Product,
        OwlCarousel,
        RecipeItem
    },
    computed:{
        products(){
            return this.$store.state.products
        }
    },
    created(){
        this.loadRecipes()
    },  
    mounted(){
        let activeHash = this.$route.hash.replace("#",'')

        if (this.$route.hash != '') {
            document.getElementById(activeHash).scrollIntoView();
        }

        this.setMetaTags({
            title: 'Kunsági Éden - Termékeink', 
            desc: '', 
            keywords: '', 
            ogType: 'website', 
            ogTitle: 'Kunsági Éden - Termékeink',
            ogImage: null, 
            ogImageWidth: null, 
            ogImageHeight: null, 
            ogDesc:''
        })
    },
    methods:{
         loadRecipes(){
        var _this = this;                       
        _this.get('recipe',
            {
                params: {      
                    limit:9,
                    relations:1,                                       
                    where: [['where','status', '1']],
                    order_by:[
                        ['created_at','desc'],
                        ['sort_order','asc']                
                    ],  
                }
            },
            function(resp){                                        
                _this.recipeList = resp.data.recipe

                //fill search helper
                for (var i in _this.recipeList){
                    _this.recipeList[i].search_helper = _this.recipeList[i].name.toLowerCase();
                    _this.recipeList[i].search_helper += " " + _this.recipeList[i].description.toLowerCase();
                    _this.recipeList[i].search_helper += " " + _this.recipeList[i].short_description.toLowerCase();
                }
                
            }, function(err){
                console.log(err);
                _this.recipeList = []
            }
        )
    },
    }
}
</script>