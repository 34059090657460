<template>
    <div class="product-item" v-if="product" :id="url_slug(productName)">
        <div class="product-item__design-img" :style="{'background-image': 'url(' + designImg + ')'  }"></div>
        <div class="product-item__data-wrap w-100">
            <b-container fluid="xl">
                <b-row>
                    <b-col cols="12" >
                        <div class="product-item__data">                            
                            <div class="prod-img">      
                                <img :src="imgurl + '/products/' + product.design_img_mobile"  class="prod-img--mobile" />                          
                                <img :src="imgurl + '/products/' + product.prod_img" :alt="product.name" class="w-100 prod-img--main" />
                            </div>
                            <div class="name-wrap">
                                <div class="name">{{productName}}</div>
                                <div class="character_icons">
                                    <div v-for="(char, cindex) in product.characteristic" :key="cindex" >
                                        <img :src=" imgurl +'/products/' + char"  />
                                    </div>
                                </div>
                            </div>                            
                            <div class="desc">{{product.desc}}</div>
                            <div class="packaging">Elérhető kiszerelések: {{product.packaging}}</div>
                            <div class="proof-wrap">
                                <ul class="proofs">
                                    <li v-for="(proof, pindex) in product.proof" :key="pindex">{{proof}}</li>
                                </ul>                                
                                <ul class="other-chars" v-if="product.other_chars.length > 0">
                                    <li v-for="(ochar, oindex) in product.other_chars" :key="oindex">
                                        <img :src="imgurl + '/products/' + ochar.img"  />
                                        <span>{{ochar.name}}</span>
                                    </li>
                                </ul>                                
                            </div>
                            <div class="product-item__main-data">
                                <div class="main-data-item main-data-item--ingredients" :class="{'active': ingredientShow}">
                                    <div class="title" @click="ingredientShow = !ingredientShow">Összetevők</div>
                                    <div class="content" >
                                        <ul>    
                                            <li v-for="(ing, iindex) in product.ingredients" :key="iindex">{{ing}}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="main-data-item main-data-item--use" :class="{'active': use}">
                                    <div class="title"  @click="use = !use">Felhasználás és tárolás</div>
                                    <div class="content" >
                                        {{product.use}}
                                    </div>
                                </div>
                                <div class="main-data-item main-data-item--nutritionTable" :class="{'active': nutrition}">
                                    <div class="title"  @click="nutrition = !nutrition">Tápérték táblázat</div>
                                    <div class="content" >
                                        <table cellpadding="0" cellspacing="0">
                                            <tr v-for="(row, rindex) in product.nutrientTable" :key="rindex">
                                                <td v-for="(td, tindex) in row" :key="tindex">
                                                    {{ td }}
                                                </td>
                                            </tr>
                                        </table>
                                        <div class="table-explanation" v-for="(explanation, eindex) in product.nutrientTableExplanation" :key="eindex">
                                            {{explanation}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>       
        </div>
    </div>
</template>

<script>
export default {
    props:['product', 'productName'],
    data(){
        return{
            ingredientShow:false,
            use: false,
            nutrition: false,
            imgurl: process.env.VUE_APP_BASE_URL
        }
    },
    computed:{
        designImg(){
            return this.imgurl + '/products/' + this.product.design_img
        }
    },
   
}
</script>